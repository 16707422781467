export const imports = {
  'docs/Call.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-call" */ 'docs/Call.md'
    ),
  'docs/Getting Started.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-getting-started" */ 'docs/Getting Started.md'
    ),
  'docs/Endpoints.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-endpoints" */ 'docs/Endpoints.md'
    ),
  'docs/Meta.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-meta" */ 'docs/Meta.md'
    ),
  'docs/Parameters.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-parameters" */ 'docs/Parameters.md'
    ),
  'docs/Styling.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-styling" */ 'docs/Styling.md'
    ),
  'docs/Permissions.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-permissions" */ 'docs/Permissions.md'
    ),
  'docs/User Experience.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-user-experience" */ 'docs/User Experience.md'
    ),
}
